import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import Link from './router/link';
import { parseJSON } from './util';
import api from './service/api';
import { useQueryProcess } from './hook/useQueryProcess';


const DivDropDown = styled.div`
  & > a + div {
    visibility:hidden;
    opacity:0;
  }

  & > a:hover + div, 
  & > a + div:hover {
    visibility:visible;
    opacity:1;
  }
`;


const LinkDropDown = ({ to = "/", className = "", children = <></> }) => {
  const [isDropDown, setIsDropDown] = useState(false);
  const refList = useRef();

  const { query } = useQueryProcess();
  const queryKey = ["menu_top"];

  const getModules = async () => {
    const { data } = await api.getAreaMenu();
    if (data?.status !== 0) return Promise.reject(new Error(data?.info));
    const dataJson = parseJSON(data?.dane);
    return dataJson;
  };
  
  const { data: moduleList } = query(
    ["client_panel_module", queryKey],
    getModules,
    {
      intialData: [],
      placeholder: [],
      staleTime: 1 * 60 * 1000,
      enable: isDropDown,
    }
  );

  useEffect(() => {
    const isClientPath = !(/\/client/.test(window.location.pathname));
    
    if (isDropDown != isClientPath)
      setIsDropDown(isClientPath);
  }, [window.location.pathname]);

  return (
    <DivDropDown className="relative">
      <Link
        to={to}
        className={`${className} flex items-center group`}
      >
        {children}
        {isDropDown && moduleList?.length > 0 && (
          <svg className="w-3 h-3 text-gray-600 group-hover:text-primary ml-2" viewBox="-3 -11 38 38" fill="currentColor">
            <path stroke="4" d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0 l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"/>
          </svg>
        )}
      </Link>
      {isDropDown && moduleList?.length > 0 && (
        <div 
          tabIndex={-1}
          ref={refList}
          className={`absolute w-full left-0 -mt-1.5 transition-opacity rounded bg-white shadow-lg outline-none`}
        >
          <ul
            role="listbox"
            aria-labelledby="menu-top-partner_zone"
            aria-activedescendant="menu-top-partner_zone-0"
            className="py-1 text-sm rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {moduleList.map((module, index) => (
              <li
                key={"panel_client_module_li_" + index}
                role="option"
                aria-selected="false"
                className="text-gray-700 cursor-default select-none hover:text-white hover:bg-primary"
              >
                <Link to={`/client/${module.id}`} className="w-full outline-none">
                  <span className="block -mt-px text-sm font-normal truncate py-2 px-4">
                    {module.name.replaceAll("\\n", " ")}
                  </span>
                </Link>
              </li> 
            ))}
          </ul>
        </div>
      )}
    </DivDropDown>
  );
};

export default LinkDropDown;
